import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Banner from 'components/banner'
import ContactForm from 'components/contact-form'
import Meta from 'components/meta'
import Section from 'components/section'
import Wrapper from 'components/wrapper'

interface Props {
  location: Location
}

const Index = ({ location }: Props) => {
  return (
    <>
      <Meta />
      <Wrapper location={location} className="d-flex w-100 h-100 mx-auto flex-column">
        <Banner bg="invert" className="p-2">
          <StaticImage
            src="../images/eco-design-wordmark-v2.png"
            alt="Eco DesignLab wordmark"
            className="mx-auto d-block"
            placeholder="none"
            layout="constrained"
            objectFit="scale-down"
            height={48}
          />
        </Banner>
        <Section bg="body">
          <Row className="justify-content-center text-center">
            <StaticImage
              src="../images/eco-design-logo.png"
              alt="Eco DesignLab logo"
              className="d-inline-block align-top"
              placeholder="none"
              layout="constrained"
              objectFit="scale-down"
              height={72}
            />
          </Row>
          <Row className="pt-3 justify-content-center text-center">
            <p className="fs-3 w-75">
              We design and build open source software and hardware systems to enhance ecological awareness through
              artistic projects. We provide opportunities for creators to learn and deploy these systems, engaging with
              new forms of expression enabled by the emerging media technologies of our time.
            </p>
          </Row>
        </Section>
        <Section>
          <h1 className="cover pt-5">Coming Soon</h1>
          <Row className="pb-5 justify-content-center text-center">
            <p className="fs-4 w-75 py-1">
              The Eco DesignLab Collective is developing a new online platform to support transdisciplinary artistic
              projects that connect body-centered performative practices with techniques for embodied human-computer
              interaction, drawing on insights gained from collaborative explorations in dance, music and theatre. The
              platform simplifies the process of creating networked multi&#8209;site performative experiences that
              continuously sense, measure and respond to movement, where participants can engage to “play their space”
              as an instrument. Each site becomes an immersive environment that responds directly to visitors and their
              motion, creating visuals and sounds that can influence the ways people move in the space.
            </p>
          </Row>
        </Section>
        <a id="contact" />
        <Section bg="body-dark" className="py-1">
          <h2 className="cover pt-5">Contact</h2>
          <Row className="pb-5 justify-content-center text-center">
            <Col xs={12} sm={10} lg={8}>
              <p className="fs-5 py-1">We&apos;d love to hear from you!</p>
              <ContactForm />
            </Col>
          </Row>
        </Section>
      </Wrapper>
    </>
  )
}

export default Index
